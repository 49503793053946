<script>
import { onMounted, toRefs, reactive } from 'vue'
import { useStore } from 'vuex'
import { maska } from 'maska'
import { internalZipLookup } from '@/util/validations'

const questionText = 'What is your zip code?'

const stepName = 'Zip'

export default {
  name: stepName,

  directives: {
    maska
  },

  setup(_, {emit}) {
    const store = useStore()

    onMounted(() => {
      store.commit('SET_IS_LOADING', false)
    })

    const state = reactive({
      zip: {
        value: '',
        error: false
      }
    })

    const getZipData = async () => {
      //check if zip length is good
      if (state.zip.value.length < 5) {
        state.zip.error = true
        return
      }

      //if zip length is okay, we can use internal lookup to validate zip further
      const zipData = await internalZipLookup(state.zip.value)
      state.zip.error = zipData.zipError

      if (state.zip.error) return

      return zipData
    }

    const setAnswer = async () => {
      const zipInfo = await getZipData(state.zip.value)
      if (!zipInfo || zipInfo.zipError) return

      const { county, city, stateCode } = zipInfo
      const answers = [
        {field: 'county', value: county},
        {field: 'city', value: city},
        {field: 'stateCode', value: stateCode},
        {field: 'zip', value: state.zip.value}
      ]
      store.commit('SET_FIELDS', answers)
      emit('next-step', stepName)
    }

    const handler = () => {
      state.zip.error = false
    }

    return {
      handler,
      setAnswer,
      questionText,
      ...toRefs(state)
    }
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="box">
        <h2 class="main-heading button-answers-heading">{{questionText}}</h2>
      </div>
    </div>

    <div class="row">
      <div class="box">
        <input
          class="form-input zip-input"
          :class="{'zip-error' : zip.error}"
          type="text"
          placeholder="Enter Zip"
          v-maska="'#####'"
          v-model="zip.value"
          @input="handler"
        >
      </div>
    </div>

    <div class="row">
      <div class="box">
        <button
          class="main-button main-button-w"
          @click="setAnswer"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import '../../../assets/styles/component_styles/questionnaire/zip.scss';
</style>